export const executorGroups = [
  {
    name: "Kubectl",
    description: "Simplifies interaction with Kubernetes",
    iconURL:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/1200px-Kubernetes_logo_without_workmark.svg.png",
    items: 3,
  },
  {
    name: "Helm v3",
    description: "Simplifies interaction with Helm releases",
    iconURL: "https://storage.googleapis.com/dashboard-icons/helm.svg",
    items: 1,
  },
  {
    name: "Argo CLI",
    description: "Simplifies interaction with Argo Workflows",
    iconURL:
      "https://storage.googleapis.com/dashboard-icons/argo-workflows.png",
    items: 1,
  },
  {
    name: "Helm v2",
    description: "Simplifies interaction with Helm releases in v2",
    iconURL: "https://storage.googleapis.com/dashboard-icons/helm.svg",
    items: 1,
  },
  {
    name: "Terraform",
    description: "Simplifies managing Terraform modules",
    iconURL: "https://storage.googleapis.com/dashboard-icons/terraform.svg",
    items: 2,
  },
];
